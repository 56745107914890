$white: #f9f9f9;
$black: #000000;
$blue: #21a1d6;
$light-blue: #32b2e6;
$green: #03cc66;
$red: #e31529;
$gray-lighter: #bdbdbd;
$gray: #333333;
$grayish-brown: #555353;
$gray-dark: #2c2c2c;

$primary: $blue;
$secondary: $white;

$is-correct: $green;
$is-wrong: $red;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


$text-color-default: $white;
$text-color-disable: $gray-lighter;
$text-shadow-default: 1px 1px 1px rgba($black, 0.4);

$link-color: $primary;

$font-family-maven-pro: 'Maven Pro', sans-serif;
$font-family-open-sans: 'OpenSans', sans-serif;
$font-family-varela-round: 'Varela Round', sans-serif;

$font-size-default: 16px;
$font-weight-max: bold;

$letter-spacing: 1px;
$line-height: 1.5;

$margin-default: 20px;
$margin-larger: 30px;

$padding-default: 25px;
$padding-larger: 25px;

$form-element-color-background: $white;
$form-element-width: 100%;
$form-element-height: $font-size-default * $line-height;
$form-element-padding-top: 10px;
$form-element-padding-bottom: 10px;
$form-element-padding-left: 15px;
$form-element-padding-right: 15px;
$form-element-box-shadow: 0 0 25px 0 rgba($black, 0.2);
$form-element-border-radius: 20px;
$form-element-font-family: $font-family-varela-round;
$form-element-text-color: $gray;
$form-element-legend-color: $white;

$radio-text-shadow: 0.5px 0.5px 1px rgba($black, 0.1);

$checkmark-radio-height: 15px;
$checkmark-radio-width: 15px;
$checkmark-checkbox-height: 20px;
$checkmark-checkbox-width: 20px;
$checkmark-margin-left: 15px;
$checkmark-margin-right: 7px;

$btn-border-radius: 30px;
$btn-box-shadow: 0 0 30px 0 $gray-dark;
$btn-background-color-default: $blue;
$btn-background-color-disable: $grayish-brown;
$btn-padding-vertical: 10px;
$btn-padding-horizontal: 30px;
$btn-font-family: 'Maven Pro', sans-serif;
$btn-font-size: 20px;
$btn-text-shadow: $text-shadow-default;
$btn-text-color-disable: $gray-lighter;
