$question-text-color: $primary;
$question-text-font-size: 20px;

.question {
  &__text {
    color: $question-text-color;
    text-shadow: $text-shadow-default;
    font-size: 20px;
    font-weight: $font-weight-max;
    margin-bottom: $margin-larger - $margin-default;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      font-size: 18px;
    }
  }

  .answer {
    margin: $margin-default 0;
  }

  form {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & > .answer {
        flex-basis: calc(50% - #{$margin-default});
        margin-left: $margin-default / 2;
        margin-right: $margin-default / 2;

        .form-element {
          height: 100%;
        }
      }
    }
  }
}
