input.btn,
.btn {
  display: inline-block;
  border-radius: $btn-border-radius;
  box-shadow: $btn-box-shadow;
  background-color: $btn-background-color-default;
  padding: $btn-padding-vertical $btn-padding-horizontal;
  color: $text-color-default;
  font-family: $btn-font-family;
  font-size: $btn-font-size;
  text-shadow: $btn-text-shadow;
  text-align: center;
  text-decoration: none;
  font-weight: 500;

  &:disabled,
  &.disabled {
    background-color: $btn-background-color-disable;
    color: $btn-text-color-disable;
    pointer-events: none;
  }

  &--small {
    font-size: 14px;
    padding: 10px 20px;
  }
}
