$magic-ball-points-color: $is-correct;
$magic-ball-streak-color: $is-correct;

.magic-ball {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 60px;

  @include media-breakpoint-down(sm) {
    padding-top: 0;
    height: 160px;
  }

  svg {
    @include media-breakpoint-down(sm) {
      transform: scale(0.6);
    }
  }

  &__points {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: $is-correct;
    font-size: 30px;
    font-weight: $font-weight-max;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  &__streak {
    position: absolute;
    z-index: 1;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    color: $magic-ball-streak-color;
    font-size: 27px;

    @include media-breakpoint-down(sm) {
      top: 95px;
      font-size: 20px;
    }

    @include media-breakpoint-down(xs) {
      top: 92px;
      font-size: 18px;
    }
  }
}
