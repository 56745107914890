$board-width: 100%;
$board-height: 70px;
$board-box-shadow: 0 2px 35px 0 rgba($black, 0.4);
$board-subtitle-font-size: 16px;

$board-break-line-height: 1px;
$board-break-line-width: 93px;
$board-padding-large: 25px;
$board-padding-medium: 10px;

$overlay-break-line: 4px;
$content-text-min-height: 37px;
$content-text-padding: 31px;
$content-text-margin: -31px;

$board-font-weight: $font-weight-max;
$board-bg-color: $gray-dark;
$board-font-family: $font-family-maven-pro;
$board-color: $white;

.board {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: $board-width;
  height: $board-height;
  box-shadow: $board-box-shadow;
  background-color: $board-bg-color;
  padding: $board-padding-medium $board-padding-large;
  color: $board-color;
  font-weight: $board-font-weight;
  font-family: $board-font-family;
  text-align: center;

  &__content-score {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &-left,
    &-right,
    &-center
    {
      width: calc(100% / 3);
    }
  }

  &__content-text {
    position: relative;
    display: flex;
    width: 100%;
    min-height: $content-text-min-height;
    padding-left: $content-text-padding;
    align-items: center;
    justify-content: center;
  }

  &__center-content-wrapper {
    display: inline-block;
    text-align: center;
    margin-left: $content-text-margin;
  }
  &__title-wrapper {
    margin-left: $content-text-margin;
  }

  &__title {
    display: inline-block;
    font-size: 22px;
  }

  &__subtitle {
    display: inline-block;
    font-size: $board-subtitle-font-size;
  }

  &__break-line-wrapper::before {
    content: "";
    display: block;
    margin: 0 -$overlay-break-line/2 0 -$overlay-break-line/2;
    width: calc(100% + #{$overlay-break-line});
    border-top: solid 1px $white;
  }

  &__points {
    font-family: $font-family-open-sans;
    font-size: $board-subtitle-font-size;
    letter-spacing: $letter-spacing;
    text-align: right;
  }

  &__return-btn {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $board-bg-color;
    border: none;
    padding: 0;
    line-height: 0;
  }

  &__lives {
    text-align: left;
  }

  .heart-empty,
  .heart-full
  {
    margin: 0 6px 0 0;
  }

  .byteout-logo {
    display: block;
    margin: auto;
  }
}
