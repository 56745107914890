$result-answer-text-shadow: 1px 1px 4px rgba($black, 0.3);
$result-user-answer-text-shadow: 0.5px 0.5px 1px rgba($black, 0.35);
$result-user-answer-box-shadow: 0 0 25px 0 rgba($black, 0.2);

$result-correct-color: $green;
$result-default-color: $white;
$result-answer-normal-color: $gray-lighter;
$result-correct-background-color: $green;
$result-wrong-background-color: $red;

.result {
  font-family: $font-family-varela-round;
  font-size: 16px;
  margin-bottom: $margin-default;
  color: $result-answer-normal-color;

  &__question {
    text-shadow: $result-answer-text-shadow;
    font-size: 18px;
    font-weight: bold;
    color: $result-default-color;
    margin-bottom: $margin-default / 2;
  }

  &--answer-is-correct,
  &--answer-is-normal {
    text-shadow: $result-answer-text-shadow;
  }

  &--answer-is-correct {
    color: $result-correct-color;
  }

  &--user-is {
    &-correct,
    &-wrong {
      margin: 0 10px 0 10px;
      text-shadow: $result-user-answer-text-shadow;
      color: $result-default-color;
    }

    &-correct-wrapper {
      background-color: $result-correct-background-color;
    }

    &-wrong-wrapper {
      background-color: $result-wrong-background-color;
    }

    &-wrong-wrapper,
    &-correct-wrapper {
      margin: 0 -10px 0 -10px;
      border-radius: 15px;
      box-shadow: $result-user-answer-box-shadow;
    }
  }
}

