* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: $font-family-maven-pro;
  line-height: $line-height;
  background-attachment: fixed;
  background-size: 100%;
  background-image: url(../../images/background.png);

  @include media-breakpoint-up(sm) {
    background-image: url(../../images/background@2x.png);
  }

  @include media-breakpoint-up(md) {
    background-image: url(../../images/background@3x.png);
  }

  & > div {
    height: 100%;
  }
}

a {
  color: $link-color;
  text-decoration: underline;
}

p {
  margin-bottom: $margin-default;
  color: $text-color-default;
  font-size: 18px;
  font-weight: $font-weight-max;
}
