.company {
  &__image {
    position: relative;
    //width: 280px;
    //height: 227px;
    margin: 0 auto $margin-default auto;
    //background-image: url(../../images/byteout-company.png);
    //background-size: 100%;

    @include media-breakpoint-up(sm) {
      //width: 465px;
      //height: 384px;
      //background-image: url(../../images/byteout-company@2x.png);
    }

    @include media-breakpoint-up(md) {
      //background-image: url(../../images/byteout-company@3x.png);
    }
  }

  &__company-image {
    max-width: 100%;
    height: auto;
  }

  &__logo {
    position: absolute;
    top: 20px;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-down(sm) {
      width: 150px;
    }
  }

  &__social {
    position: absolute;
    right: 20px;
    bottom: 29px;

    li {
      width: 40px;
      height: 40px;
      margin-bottom: 15px;

      @include media-breakpoint-down(xs) {
        width: 30px;
        height: 30px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      img {
        width: 40px;
      }
    }
  }

  &__text {
    p {
      font-size: 18px;

      @include media-breakpoint-up(md) {
        font-size: 23px;
        text-align: center;
        max-width: 450px;
        margin: 20px auto;
      }

      &:last-of-type {
        font-size: 16px;

        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
      }
    }
  }
}
