@mixin formInputLabelColor($color) {
  color: $color;

  // Need to specified for radio svg 'cause svg is not properly created
  .radio-check-mark circle {
    &:first-of-type {
      stroke: $color;
    }

    &:last-of-type {
      fill: $color;
    }
  }

  .radio-check-mark-unchecked circle {
    stroke: $color;
  }
}
