$list-color: $white;
$list-font-size: 16px;

.list {
  margin-bottom: $margin-default;
  font-size: $list-font-size;
  font-family: $font-family-maven-pro;
  color: $list-color;
  text-shadow: $text-shadow-default;
  list-style-type: disc;
  margin-left: $padding-default;

  &__item {
    margin-bottom: $margin-default;
  }
}
