$answer-selected-color: $primary;

$answer-correct-color: $white;
$answer-correct-bg: $is-correct;

$answer-error-color: $white;
$answer-error-bg: $is-wrong;

.answer {
  &--is-selected {
    .form-element {
      @include formInputLabelColor($answer-selected-color);

      input[type="radio"] {
        & + label,
        &[disabled] + label {
          @include formInputLabelColor($answer-selected-color);
        }
      }
    }
  }

  &--is-correct {
    .form-element {
      @include formInputLabelColor($answer-correct-color);
      background-color: $answer-correct-bg;

      input[type="radio"] {
        & + label,
        &[disabled] + label {
          @include formInputLabelColor($answer-correct-color);
        }
      }
    }
  }

  &--is-error {
    .form-element {
      @include formInputLabelColor($answer-error-color);
      background-color: $answer-error-bg;

      input[type="radio"] {
        & + label,
        &[disabled] + label {
          @include formInputLabelColor($answer-error-color);
        }
      }
    }
  }

  &--is-correct,
  &--is-error {
    input[type="radio"] {
      &[disabled] + label {
        color: $white;

        .radio-checkmark circle {
          stroke: $white;
        }
      }
    }
  }
}
