.quiz {
  display: flex;
  flex-direction: column;
  margin-top: $board-height;
  animation: fadeIn 0.5s ease-in;

  @include media-breakpoint-down(md) {
    margin-top: $board-height - $margin-default;
  }

  .magic-ball {
    @include media-breakpoint-up(md) {
      margin-bottom: $margin-larger;
    }
  }

  p {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  &__quit {
    text-align: center;
  }

  & > .btn {
    margin: 40px auto 0 auto;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 385px) {
      flex-direction: column;
    }

    .btn {
      &:first-of-type {
        margin-right: $margin-default;

        @media (max-width: 385px) {
          margin-bottom: $margin-default;
          margin-right: 0;
        }
      }
    }
  }

  &--end {
    .magic-ball {
      height: auto;
      margin-top: $margin-default;
      margin-bottom: $margin-default;

      svg {
        transform: scale(1);
      }
    }
  }
}
