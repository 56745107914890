@import 'util/variables';
@import 'util/mixins';
@import 'util/breakpoints';
@import 'util/animations';

@import "~css-reset-and-normalize/scss/button-reset";
@import "~css-reset-and-normalize/scss/link-reset";
@import "~css-reset-and-normalize/scss/reset-and-normalize";

@import "theme/base";
@import "theme/form-elements";
@import "theme/page";

@import 'components/answer';
@import 'components/board';
@import "components/button";
@import "components/company";
@import "components/magic-ball";
@import "components/list";
@import "components/progress-bar";
@import "components/result";
@import "components/question";
@import "components/quiz";
