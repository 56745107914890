.form {
  @media (max-width: 425px) {
    flex-grow: 1;
  }

  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 1;
  }

  &__button {
    align-self: center;
  }

  &-element {
    display: flex;
    align-items: center;
    width: $form-element-width;
    border-radius: $form-element-border-radius;
    box-shadow: $form-element-box-shadow;
    font-family: $form-element-font-family;
    color: $form-element-text-color;
    background-color: $form-element-color-background;
    font-size: $font-size-default;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    input[type="radio"],
    input[type="checkbox"] {
      display: none;

      & + label {
        @include formInputLabelColor($form-element-text-color);
        width: 100%;
        display: flex;
        align-items: center;
        padding: $form-element-padding-top $form-element-padding-right $form-element-padding-bottom $form-element-padding-left;

        svg {
          position: relative;
          // Not align properly with label text so we must move svg slightly up
          top: -0.1px;
          flex-shrink: 0;
          margin-right: $checkmark-margin-right;
          fill: $form-element-text-color;
        }
      }

      &[disabled] + label {
        @include formInputLabelColor($text-color-disable);

        svg {
          fill: $text-color-disable;
        }
      }
    }

    &__radio {
      text-shadow: $radio-text-shadow;

      .radio-check-mark {
        width: $checkmark-radio-width;
        height: $checkmark-radio-height;

      }
    }

    &__checkbox {
      .checkbox-check-mark {
        width: $checkmark-checkbox-width;
        height: $checkmark-checkbox-height;
      }
    }

    &__text-field {
      padding-top: $form-element-padding-top;
      padding-bottom: $form-element-padding-bottom;
      padding-left: $form-element-padding-left;
      border: none;
    }

    &__fieldset {
      margin-bottom: $margin-larger;

      &-legend {
        padding-left: $form-element-padding-left;
        margin: 0 0 10px 0;
        font-size: 20px;
        font-weight: $font-weight-max;
        text-align: left;
        color: $form-element-legend-color;
        text-shadow: $text-shadow-default;
      }
    }

    &__group {
      & > * {
        margin-bottom: $margin-default;
      }
    }
  }

  ::placeholder {
    color: $text-color-disable;
    font-family: $form-element-font-family;
    font-size: $font-size-default;
  }
}

