.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  .byteout-logo {
    display: block;
    margin: 0 auto;
  }

  &__title {
    text-shadow: $text-shadow-default;
    font-size: 35px;
    font-weight: $font-weight-max;
    color: $primary;
    margin: $margin-larger 0;
    text-align: center;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: $padding-larger $padding-default 0 $padding-default;
    width: 100%;
    //animation: fadeIn 0.7s ease-in;

    & > * {
      flex-shrink: 0;
    }

    @include media-breakpoint-up(md) {
      flex-grow: 0;
      width: 700px;
    }

    @include media-breakpoint-up(lg) {
      flex-grow: 0;
      width: 900px;
    }
  }

  &--with-board {
    padding-top: 100px;
  }
}
