$progress-bar-border-height: 1px;
$progress-bar-border: $progress-bar-border-height solid rgba($light-blue, 0.1);
$progress-bar-height: 7px;
$progress-bar-radius: 5px;

$progress-bar-wrapper-bg-color: $gray-dark;
$progress-bar-bg-color: $light-blue;

.progress-bar {
  position: fixed;
  z-index: 5;
  top: $board-height;
  left: 0;
  width: 100%;
  height: $progress-bar-height;
  background-color: $progress-bar-wrapper-bg-color;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-top: $progress-bar-border;
  }

  ::after{
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-bottom: $progress-bar-border;
  }

  &--ongoing,
  &--end {
    height: $progress-bar-height;
    background-color: $progress-bar-bg-color;
  }

  &--ongoing {
    border-top-right-radius: $progress-bar-radius;
    border-bottom-right-radius: $progress-bar-radius;
  }

  &--end {
  }
}
